import Vue from "vue";
import Vuex from "vuex";
import SiteModule from './site.module'
import SessionModule from './session.module'

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        site: SiteModule,
        session: SessionModule
    }
});
