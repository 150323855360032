<template>
  <v-main fluid class="fill-height">
    <v-stepper v-model="stepper" class="fill-height" alt-labels style="overflow: visible;">
      <v-stepper-header v-if="steps" style="top: 0; position: sticky; z-index: 5; background-color: white">
        <v-stepper-step :complete="stepper > 1" step="1" :editable="stepper > 1">Location</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="stepper > 2" step="2" :editable="stepper > 2">Product</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="stepper > 3" step="3" :editable="stepper > 3">Resource</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="stepper > 4" step="4" :editable="stepper > 4">Date & Time</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="stepper > 5" step="5">Confirm</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <LocationStep step="1" :locations="locations" v-on:complete="locationSelected"></LocationStep>
        <ProductStep step="2" :products="products" v-on:complete="productSelected"></ProductStep>
        <ResourceStep step="3" :resources="resources" v-on:complete="resourceSelected"></ResourceStep>
        <DateTimeStep step="4" :product="product" v-on:complete="dateTimeSelected" :availabilities="availabilities" :start="startDate"></DateTimeStep>
        <ConfirmStep step="5" :location="location" :siteId="siteId" :product="product" :resource="resource" :date-time="appointmentDateTime"></ConfirmStep>
      </v-stepper-items>
    </v-stepper>

    <v-btn fixed large bottom tile block color="primary" style="bottom: 0" @click="stepper--" v-if="stepper > 1">Back</v-btn>
  </v-main>
</template>

<script>
import {mapGetters} from "vuex";
import LocationStep from "@/components/LocationStep";
import ProductStep from "@/components/ProductStep";
import DateTimeStep from "@/components/DateTimeStep";
import ResourceStep from "@/components/ResourceStep";
import dayjs from "dayjs";
import ConfirmStep from "@/components/ConfirmStep";

export default {
  name: "MakeReservation",
  components: {ConfirmStep, ResourceStep, DateTimeStep, ProductStep, LocationStep},
  props: ["siteId"],

  created() {
    this.$store.dispatch('user', {siteId: this.siteId})

    const sitePromise = this.$store.dispatch('site', this.siteId);
    const locationsPromise = this.$store.dispatch('locations', this.siteId);
    const productsPromise = this.$store.dispatch('products', this.siteId);
    const resourcesPromise = this.$store.dispatch('resources', this.siteId);

    Promise.all([sitePromise, locationsPromise, productsPromise, resourcesPromise]).finally(() => {
      this.$data.loading = false;
      // this sets the page title to the title of the site
      document.title = this.$store.getters.site.title;
    })
  },

  methods: {
    locationSelected(location) {
      this.$data.location = location;
      this.$data.products = this.$store.getters.products.filter(p => p.location === location.id);
      this.$data.stepper = 2;
    },

    productSelected(product) {
      this.$data.product = product;

      // Filter out the correct resources for the selected product
      this.$data.resources = this.$store.getters.resourcesByProduct(product);
      this.$data.stepper = 3;
    },

    resourceSelected(resource) {
      const minNoticeInDays = dayjs.duration(this.$data.product.bookingMinNotice, 'minutes').asDays();
      const minNoticeDate = dayjs().add(minNoticeInDays, 'days');
      const maxNoticeInDays = dayjs.duration(this.$data.product.bookingMaxNotice, 'minutes').asDays();
      let maxNoticeDate = dayjs().add(maxNoticeInDays, 'days');

      if (maxNoticeDate.isSameOrBefore(minNoticeDate, 'day')) {
        maxNoticeDate = dayjs().add(60, 'days');
      }

      this.$data.startDate = minNoticeDate.format('YYYY-MM-DD');
      this.$data.resource = resource;

      this.$store.dispatch('availability', {
        siteId: this.siteId,
        productId: this.$data.product.id,
        from: this.$data.startDate,
        to: maxNoticeDate.format('YYYY-MM-DD'),
        resource: resource
      }).then((availabilities) => {
        this.$data.availabilities = availabilities;
        this.$data.stepper = 4;
      });
    },

    dateTimeSelected(dateTime) {
      this.$data.appointmentDateTime = dateTime;
      this.$data.stepper = 5;
    }
  },

  data() {
    return {
      stepper: 1,
      loading: true,

      location: {},

      product: {},
      products: [],

      resource: {},
      resources: [],

      availabilities: {},
      startDate: '',
      appointmentDateTime: null
    }
  },

  computed: {
    topBar() {
      return !this.$route.query.topbar || this.$route.query.topbar === '1';
    },

    steps() {
      return !this.$route.query.steps || this.$route.query.steps === '1';
    },

    ...mapGetters(['site', 'locations'])
  }
}
</script>
