import axios from "axios";

function siteUrl(siteId) {
    // If the site id appears to be a subdomain only
    if (siteId.indexOf(".") === -1) {
        if (window.location.hostname === 'localhost') {
            return window.location.protocol + '//' + siteId + process.env.VUE_APP_API_DEFAULT_DOMAIN;
        }

        let hostname = window.location.hostname;
        if (hostname.startsWith('app.')) {
            hostname = hostname.substring(3);
        }

        return window.location.protocol + '//' + siteId + hostname;
    }

    return window.location.protocol + '//' + siteId;
}

axios.interceptors.request.use(function (config) {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
        config.headers.common['Authorization'] = "Bearer " + accessToken;
    }

    return config;
}, function (error) {
    return Promise.reject(error);
});

export default {
    state: {
        site: {},
        products: [],
        locations: [],
        resources: [],
        availability: {},
        user: null,
        reservation: null
    },

    mutations: {
        clear(state) {
            state.site = {};
            state.products = [];
            state.locations = [];
            state.resources = [];
            state.availability = {};
            state.user = null;
            state.reservation = null;
        },

        site(state, site) {
            if (!site) {
                return;
            }

            state.site = site;
        },

        locations(state, locations) {
            if (!locations) {
                return;
            }

            state.locations = locations;
        },

        products(state, products) {
            if (!products) {
                return;
            }

            state.products = products;
        },

        resources(state, resources) {
            if (!resources) {
                return;
            }

            state.resources = resources;
        },

        availability(state, availability) {
            if (!availability) {
                return;
            }

            state.availability = availability;
        },

        user(state, user) {
            if (!user) {
                return;
            }

            state.user = user;
        }
    },

    getters: {
        site(state) {
            return state.site;
        },

        locations(state) {
            return state.locations;
        },

        products(state) {
            return state.products;
        },

        resources(state) {
            return state.resources;
        },

        resourceById: (state) => (id) => {
            return state.resources.find(r => r.id === id);
        },

        resourcesByProduct: () => (product) => {
            const resources = [];

            product.resourceGroups.forEach(function (group) {
                let canSelectResource = false;

                //  0: cant choose, 1: May, 2: Must
                if (group.groupBreakup === 2) {
                    canSelectResource = true;
                } else if (group.groupBreakup === 1 && group.resources.length > 0) {
                    canSelectResource = true;
                }

                if (canSelectResource) {
                    group.resources.forEach(function (resource) {
                        resources.push({
                            groupId: group.id,
                            resourceId: resource
                        });
                    });

                    product.canSelectResource =  true;
                }
            });

            return resources;
        },

        locationById: (state) => (id) => {
            return state.site.locations.find(location => location.id === parseInt(id, 10));
        },

        user(state) {
            return state.user;
        }
    },

    actions: {
        site({commit}, siteId) {
            const url = siteUrl(siteId) + '/api/sites/current';
            return axios.get(url)
                .then(function (response) {
                    if (response.status !== 200) {
                        this.$route.push('/notfound')
                    }

                    commit('site', response.data);
                });
        },

        locations({commit}, siteId) {
            const url = siteUrl(siteId) + '/api/sites/current/locations';
            return axios.get(url)
                .then(function (response) {
                    if (response.status !== 200) {
                        this.$route.push('/notfound')
                    }

                    commit('locations', response.data);
                });
        },

        resources({commit}, siteId) {
            const url = siteUrl(siteId) + '/api/sites/current/team?include-resources=true';
            return axios.get(url)
                .then(function (response) {
                    if (response.status !== 200) {
                        this.$route.push('/notfound')
                    }

                    commit('resources', response.data);
                });
        },

        products({commit}, siteId) {
            const url = siteUrl(siteId) + '/api/sites/current/products';

            return axios.get(url)
                .then(function (response) {
                    if (response.status !== 200) {
                        this.$route.push('/notfound')
                    }

                    commit('products', response.data);
                });
        },

        availability({commit}, {siteId, productId, from, to, resource}) {
            let url = siteUrl(siteId) + '/api/sites/current/products/' + productId + '/availability?from=' + from + '&to=' + to;

            if (resource && resource.groupId !== 0 && resource.resourceId !== 0) {
                url += '&resource=' + resource.groupId + ':' + resource.resourceId;
            }

            return new Promise(resolve => {
                axios.get(url)
                    .then(function (response) {
                        if (response.status !== 200) {
                            this.$route.push('/notfound')
                        }

                        commit('availability', response.data);
                        resolve(response.data);
                    });
            });
        },

        user({commit}, {siteId}) {
            const url = siteUrl(siteId) + '/api/sites/current/users/current';

            axios.get(url)
                .then(function (response) {
                    if (response.status !== 200) {
                        this.$route.push('/notfound')
                    }

                    commit('user', response.data);
                });
        },

        login({dispatch}, {siteId, credentials}) {
            const url = siteUrl(siteId) + '/api/sites/current/authenticate';

            return new Promise(resolve => {
                axios.post(url, credentials)
                    .then(function (response) {
                        if (response.status !== 200) {
                            this.$route.push('/notfound')
                        }

                        localStorage.setItem("accessToken", response.data.accessToken);

                        dispatch('user', {siteId}).then(function() {
                            resolve();
                        });
                    });
            });
        },

        makeReservation({state}, {siteId, productId, start, restrictions}) {
            const url = siteUrl(siteId) + '/api/sites/current/users/current/reservations';

            return new Promise(resolve => {
                axios.post(url, {start, productId, restrictions})
                    .then(function (response) {
                        state.reservation = response.data;
                        resolve(response.data);
                    });
            });
        }
    }
};
