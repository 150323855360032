export default {
    state: {
        selectedLocation: {},
        selectedProduct: {}
    },

    mutations: {
        reset(state) {
            state.selectedLocation = {};
            state.selectedProduct = {};
        },

        selectedLocation(state, selectedLocation) {
            if (!selectedLocation) {
                return;
            }

            state.selectedLocation = selectedLocation;
        }
    },

    getters: {
        selectedLocation(state) {
            return state.selectedLocation;
        }
    },

    actions: {
    }
};
