<template>
  <v-stepper-content :step="step" class="fill-height">
    <v-list-item link @click="$emit('complete', {groupId: 0, resourceId: 0})">
      <v-list-item-content>
        <v-list-item-title>No preference</v-list-item-title>
      </v-list-item-content>
      <v-list-item-avatar>
        <v-icon>mdi-chevron-right</v-icon>
      </v-list-item-avatar>
    </v-list-item>
    <v-list-item link @click="$emit('complete', resource)" v-for="resource in resources" :key="resource.resourceId" >
      <v-list-item-content>
        <v-list-item-title>{{ resourceById(resource.resourceId).name }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-avatar>
        <v-icon>mdi-chevron-right</v-icon>
      </v-list-item-avatar>
    </v-list-item>
  </v-stepper-content>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ResourceStep",
  props: ["step", "resources"],
  computed: {
    ...mapGetters(["resourceById"])
  }
}
</script>
