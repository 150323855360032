<template>
  <v-app>
    <v-container fluid class="fill-height" v-if="loading">
      <v-row align="center" justify="center">
        <v-col class="text-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>

    <div v-if="!loading" class="fill-height">
      <v-app-bar dark color="primary" app v-if="topBar">
        <v-toolbar-title>{{site.title}}</v-toolbar-title>
      </v-app-bar>

     <router-view></router-view>
    </div>
  </v-app>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Home",
  props: ["siteId"],

  created() {
    this.$store.dispatch('user', {siteId: this.siteId})

    const sitePromise = this.$store.dispatch('site', this.siteId);
    const locationsPromise = this.$store.dispatch('locations', this.siteId);
    const productsPromise = this.$store.dispatch('products', this.siteId);
    const resourcesPromise = this.$store.dispatch('resources', this.siteId);

    Promise.all([sitePromise, locationsPromise, productsPromise, resourcesPromise]).finally(() => {
      this.$data.loading = false;
      // this sets the page title to the title of the site
      document.title = this.$store.getters.site.title;
    });
  },

  data() {
    return {
      loading: true
    }
  },

  computed: {
    topBar() {
      return !this.$route.query.topbar || this.$route.query.topbar === '1';
    },

    steps() {
      return !this.$route.query.steps || this.$route.query.steps === '1';
    },

    ...mapGetters(['site', 'locations'])
  }
}
</script>
