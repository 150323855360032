<template>
  <v-stepper-content :step="step" class="fill-height">
    <v-list-item link @click="$emit('complete', location)" v-for="location in locations" :key="location.id" >
      <v-list-item-content>
        <v-list-item-title>{{ location.name }}</v-list-item-title>
        <v-list-item-subtitle v-if="location.description">{{ location.description }}</v-list-item-subtitle>
        <v-list-item-subtitle v-if="location.postcode">{{ location.postcode }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-avatar>
        <v-icon>mdi-chevron-right</v-icon>
      </v-list-item-avatar>
    </v-list-item>
  </v-stepper-content>
</template>

<script>
export default {
  name: "LocationStep",
  props: ["step", "locations"]
}
</script>
