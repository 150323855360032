<template>
  <v-form ref="loginForm">
    <v-text-field v-model="credentials.username" type="email" label="Email"></v-text-field>
    <v-text-field v-model="credentials.password"  type="password" label="Password"></v-text-field>
    <v-btn color="primary" tile block large @click="$emit('login', credentials)">Login</v-btn>
  </v-form>
</template>

<script>
export default {
  name: "LoginForm",

  data() {
    return {
      credentials:{
        username: '',
        password: ''
      }
    }
  }
}
</script>
