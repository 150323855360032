import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/Home';
import NotFound from "@/views/NotFound";
import ReservationSuccessful from "@/views/ReservationSuccessful";
import MakeReservation from "@/views/MakeReservation";

Vue.use(Router);

const router = new Router({

    mode: 'history',

    routes: [
        {
            path: '/sites/:siteId',
            component: Home,
            props: true,
            children: [
                {
                    name: 'MakeReservation',
                    path: '/sites/:siteId/reservation',
                    component: MakeReservation,
                    props: true
                },
                {
                    name: 'ReservationSuccessful',
                    path: '/sites/:siteId/reservation/success',
                    component: ReservationSuccessful,
                    props: true
                }
            ]
        },
        {
            path: '*',
            component: NotFound,
            meta: {
                secure: false
            }
        }
    ]
});

router.beforeEach((to, from, next) => {
/*
    if (to.meta.secure === false) {
        next();
    } else {
        Promise.all([store.dispatch('checkAuthentication')])
            .then(() => next())
            .catch(() => next('/signin'));
    }
*/

    next();
});

export default router;
