<template>
  <v-main fluid class="fill-height">
    <v-container fluid>
      <h1>Success</h1>
      <v-alert dark icon="mdi-calendar" tile color="success">Thank you, your reservation has been made, please see below for details.</v-alert>
      <v-card tile class="mb-4">
        <v-card-title>Details</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item>
              <v-list-item-icon><v-icon>mdi-basket</v-icon></v-list-item-icon>
              <v-list-item-title>Welcome Session</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon><v-icon>mdi-calendar</v-icon></v-list-item-icon>
              <v-list-item-title>2 April 2021</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon><v-icon>mdi-clock</v-icon></v-list-item-icon>
              <v-list-item-title>10:45</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>

      <v-btn color="primary" tile block :to="{name: 'MakeReservation'}">BOOK ANOTHER</v-btn>
    </v-container>
  </v-main>
</template>

<script>

export default {
  name: "ReservationSuccessful"
}
</script>
