<template>
  <v-stepper-content :step="step">
    <div v-if="products.length === 0">Sorry</div>

    <v-list-item link @click="$emit('complete', product)" v-for="product in products" :key="product.id" >
      <v-list-item-content>
        <v-list-item-title>{{ product.name }}</v-list-item-title>
        <v-list-item-subtitle v-if="product.description">{{ product.description }}</v-list-item-subtitle>
        <v-list-item-subtitle v-if="product.duration">{{ formatDuration(product.duration) }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-avatar>
        <v-icon>mdi-chevron-right</v-icon>
      </v-list-item-avatar>
    </v-list-item>
  </v-stepper-content>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "ProductStep",
  props: ["step", "products"],

  methods: {
    formatDuration(duration) {
      return dayjs.duration(duration, 'minutes').humanize();
    }
  }
}
</script>
