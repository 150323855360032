<template>
  <v-stepper-content :step="step" class="fill-height">
    <v-container fluid>
      <h2>Confirm</h2>
      <p>Please confirm you wish to book an appointment for <strong>{{ productName }}</strong> with <strong>{{ resourceName }}</strong> on <strong>{{ appointmentDate }}</strong> at <strong>{{ appointmentTime }}</strong></p>

      <v-expansion-panels mandatory v-model="panel" v-if="!user">
        <v-expansion-panel>
          <v-expansion-panel-header>Login</v-expansion-panel-header>
          <v-expansion-panel-content>
            <LoginForm v-on:login="login"></LoginForm>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Register</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form ref="registerForm">
              <v-text-field type="text" label="First Name"></v-text-field>
              <v-text-field type="text" label="Last Name"></v-text-field>
              <v-text-field type="email" label="Email"></v-text-field>
              <v-text-field type="password" label="Password"></v-text-field>
              <v-btn color="primary" tile block large>Register</v-btn>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-btn class="mt-4" color="success" block large tile v-if="user" @click="makeReservation">Book Now</v-btn>
    </v-container>
  </v-stepper-content>
</template>

<script>
import {mapGetters} from "vuex";
import LoginForm from "@/components/LoginForm";

export default {
  name: "ConfirmStep",
  components: {LoginForm},
  props: ["siteId", "step", "location", "product", "resource", "dateTime"],

  data() {
    return {
      panel: 0
    }
  },

  methods: {
    login(credentials) {
      this.$store.dispatch('login', {credentials, siteId: this.siteId})
    },

    makeReservation() {
      const restrictions = [];

      if (this.resource && this.resource.resourceId && this.resource.resourceId !== 0) {
        restrictions.push({
          group: this.resource.groupId,
              resources: [this.resource.resourceId]
        });
      }

      this.$store.dispatch('makeReservation', {siteId: this.siteId, productId: this.product.id, start: this.dateTime.valueOf(), restrictions})
      .then(() => {
        this.$router.push({ name: 'ReservationSuccessful', params: { siteId: this.siteId } });
      })
    }
  },

  computed: {
    productName() {
      if (this.product) {
        return this.product.name;
      }

      return '';
    },

    appointmentDate() {
      if (this.dateTime) {
        return this.dateTime.format('D MMMM, YYYY');
      }

      return '';
    },

    appointmentTime() {
      if (this.dateTime) {
        return this.dateTime.format('H:mm');
      }

      return '';
    },

    resourceName() {
      if (this.resource && this.resource.resourceId && this.resource.resourceId !== 0) {
        return this.resourceById(this.resource.resourceId).name;
      }

      return 'no preference';
    },

    ...mapGetters(['resourceById', 'user', 'site'])
  }
}
</script>
