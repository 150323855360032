import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

function camelize(str){
    let arr = str.split('-');
    let capital = arr.map((item, index) => index ? item.charAt(0).toUpperCase() + item.slice(1).toLowerCase() : item.toLowerCase());
    // ^-- change here.
    return capital.join("");
}

const urlParams = new URLSearchParams(window.location.search);
let color = urlParams.has('color') ? camelize(urlParams.get('color')) : 'blue';

if (!colors[color]) {
    color = 'blue';
}

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: colors[color],
                secondary: colors[color].secondary,
                accent: colors[color].accent1
            },
        },
    }
})