<template>
  <v-stepper-content :step="step" class="pa-0">
    <v-sheet style="top: 0; position: sticky; z-index: 4">
      <v-slide-group show-arrows center-active v-model="model" mandatory>
        <v-slide-item v-for="date in dates" :key="date.key" v-slot="{active, toggle}">
          <v-card light flat class="ml-2 mr-2" @click="toggle" active-class="success">
            <div class="text-center text-overline">{{ date.day }}</div>
            <v-btn fab elevation="0" :color="active ? 'primary' : 'grey lighten-2'">{{ date.dayOfMonth }}</v-btn>
            <div class="text-center text-overline">{{ date.month }}</div>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>

    <v-list-item link @click="$emit('complete', getDateTime(time))" v-for="time in times" :key="time" >
      <v-list-item-content>
        <v-list-item-title>{{ time }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-avatar>
        <v-icon>mdi-chevron-right</v-icon>
      </v-list-item-avatar>
    </v-list-item>
  </v-stepper-content>
</template>

<script>
import dayjs from 'dayjs'


export default {
  name: "DateTimeStep",
  props: ["step", "product", "availabilities", "start"],

  data: () => ({
    model: 0
  }),

  methods: {
    getDateTime(time) {
      const date = dayjs(this.start, 'YYYY-MM-DD').add(this.model, 'day');
      return dayjs(date.format('YYYY-MM-DD') + ' ' + time, 'YYYY-MM-DD HH:mm');
    }
  },

  computed: {
    times() {
      const date = dayjs(this.start, 'YYYY-MM-DD').add(this.model, 'day');
      return this.availabilities[date.format('YYYY-MM-DD')];
    },

    dates() {
      const availabilityKeys = Object.keys(this.availabilities);
      const startDate = dayjs(this.start, 'YYYY-MM-DD');
      const dates = [];

      for (let i = 0 ; i < availabilityKeys.length; i++) {
        const theDate = startDate.add(i, "day");

        dates.push({
          key: i,
          date: theDate,
          day: theDate.format('ddd'),
          dayOfMonth: theDate.format('D'),
          month: theDate.format('MMM')
        });
      }

      return dates;
    }
  }
}
</script>
