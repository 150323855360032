<template>
    <v-container class="fill-height" fluid>
        Oops! What are you looking for?
    </v-container>
</template>

<script>
    export default {
        name: "NotFound"
    }
</script>

<style lang="css" scoped>
</style>